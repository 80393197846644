import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'cashiering',
    loadChildren: () => import('./cashiering/cashiering.module').then( m => m.CashieringPageModule)
  },
  {
    path: 'betting',
    loadChildren: () => import('./betting/betting.module').then( m => m.BettingPageModule)
  },
  {
    path: 'payout',
    loadChildren: () => import('./payout/payout.module').then( m => m.PayoutPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'console',
    loadChildren: () => import('./console/console.module').then( m => m.ConsolePageModule)
  },
  {
    path: 'scanner',
    loadChildren: () => import('./scanner/scanner.module').then( m => m.ScannerPageModule)
  },
  {
    path: 'startshift',
    loadChildren: () => import('./startshift/startshift.module').then( m => m.StartshiftPageModule)
  },
  {
    path: 'addcash',
    loadChildren: () => import('./addcash/addcash.module').then( m => m.AddcashPageModule)
  },
  {
    path: 'removecash',
    loadChildren: () => import('./removecash/removecash.module').then( m => m.RemovecashPageModule)
  },
  {
    path: 'download',
    loadChildren: () => import('./download/download.module').then( m => m.DownloadPageModule)
  },
  {
    path: 'endshift',
    loadChildren: () => import('./endshift/endshift.module').then( m => m.EndshiftPageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'obs',
    loadChildren: () => import('./obs/obs.module').then( m => m.ObsPageModule)
  },
  {
    path: 'tv',
    loadChildren: () => import('./tv/tv.module').then( m => m.TvPageModule)
  },
  {
    path: 'tv2',
    loadChildren: () => import('./tv2/tv2.module').then( m => m.Tv2PageModule)
  },
  {
    path: 'panel1',
    loadChildren: () => import('./panel1/panel1.module').then( m => m.Panel1PageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'kick',
    loadChildren: () => import('./kick/kick.module').then( m => m.KickPageModule)
  },
  {
    path: 'panel2',
    loadChildren: () => import('./panel2/panel2.module').then( m => m.Panel2PageModule)
  },
  {
    path: 'panel3',
    loadChildren: () => import('./panel3/panel3.module').then( m => m.Panel3PageModule)
  },
  {
    path: 'viptv',
    loadChildren: () => import('./viptv/viptv.module').then( m => m.ViptvPageModule)
  },
  {
    path: 'timeout',
    loadChildren: () => import('./timeout/timeout.module').then( m => m.TimeoutPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
